import React from 'react';
import styles from './index.module.css';
import { ICard } from '../../interfaces/card.interface';
import classnames from 'classnames';
import { whiteColors } from '../../services/constants';

interface ICardParams {
  item: ICard;
  isClickable: boolean;
  onClick?: () => void;
  size?: string;
}

const Card = ({ item, isClickable, onClick, size }: ICardParams) => {
  const onCardClick = () => {
    if (isClickable && onClick) {
      onClick();
    }
  };

  return (
    <div
      className={classnames(
        styles.item,
        size ? styles[`--${size}`] : styles['--usual'],
        isClickable ? styles['--clickable'] : null,
        whiteColors.includes(item.color) ? styles['--white'] : null
      )}
      style={{ backgroundColor: item.color }}
      onClick={onCardClick}
    >
      <div className={styles.name}>{item.name}</div>
      {size !== 'small' && <div className={styles.description}>{item.description}</div>}
      {size !== 'small' && (
        <div
          className={styles.img}
          style={{
            backgroundImage: `url('/cards/${encodeURIComponent(item.name.replaceAll("'", ''))}.jpg')`
          }}
        />
      )}
    </div>
  );
};

export default Card;
