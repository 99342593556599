import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { ICard } from '../../interfaces/card.interface';
import Card from '../card';

interface IPlayTableParams {
  cards: ICard[];
  isRandom: boolean;
  children: any;
  onFinished: (cards: ICard[]) => void;
}

const PlayTable = ({ cards, children, isRandom, onFinished }: IPlayTableParams) => {
  const [cardsToPlayByColors, setCardsToPlayByColors] = useState({} as { [key: string]: ICard[] });
  const [cardsInPlay, setCardsInPlay] = useState([] as ICard[]);
  const [selectedCards, setSelectedCards] = useState([] as ICard[]);
  const [cardsToPlay, setCardsToPlay] = useState([] as ICard[]);

  const randomBetween = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const getNextCardToPlay = (cardsToPlayByColors: { [key: string]: ICard[] }) => {
    const allColorsToPlay = Object.keys(cardsToPlayByColors);
    const isFewColors = allColorsToPlay.length > 1;
    const firstRandomColorIndex = randomBetween(0, allColorsToPlay.length - 1);
    const secondRandomColorIndex = isFewColors ? randomBetween(0, allColorsToPlay.length - 2) : firstRandomColorIndex;

    const firstColor = allColorsToPlay[firstRandomColorIndex];
    const restColors = isFewColors ? allColorsToPlay.filter((color: string) => color !== firstColor) : allColorsToPlay;
    const secondColor = restColors[secondRandomColorIndex];

    const firstItems = [...cardsToPlayByColors[firstColor]];
    const firstItemIndex = randomBetween(0, firstItems.length - 1);
    const secondItems = [...cardsToPlayByColors[secondColor]];
    if (!isFewColors) {
      secondItems.splice(firstItemIndex, 1);
    }
    const secondItemIndex = randomBetween(0, secondItems.length - 1);

    const firstItem = firstItems[firstItemIndex];
    const secondItem = secondItems[secondItemIndex];

    const firstColorRestItems = [...firstItems];
    firstColorRestItems.splice(firstItemIndex, 1);
    const secondColorRestItems = [...secondItems];
    secondColorRestItems.splice(secondItemIndex, 1);

    const cardsByColor = {
      ...cardsToPlayByColors,
      [firstColor]: firstColorRestItems,
      [secondColor]: secondColorRestItems
    };
    if (firstColorRestItems.length === 0) {
      delete cardsByColor[firstColor];
    }
    if (secondColorRestItems.length === 0) {
      delete cardsByColor[secondColor];
    }
    setCardsToPlayByColors(cardsByColor);
    setCardsInPlay([firstItem, secondItem]);
  };

  const getNextCardsToPlayDirect = (cards: ICard[]) => {
    if (cards.length >= 2) {
      setCardsInPlay([cards[0], cards[1]]);
      setCardsToPlay(cards.slice(2));
    }
  };

  useEffect(() => {
    const cardsByColor: { [key: string]: ICard[] } = {};

    cards.forEach((item: ICard) => {
      cardsByColor[item.color] = cardsByColor[item.color] || [];
      cardsByColor[item.color].push(item);
    });

    if (isRandom) {
      setCardsToPlayByColors(cardsByColor);
      getNextCardToPlay(cardsByColor);
    } else {
      setCardsToPlay(cards);
      getNextCardsToPlayDirect(cards);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPlayCardClick = (item: ICard): void => {
    const allSelectedCards = [...selectedCards, item];

    let allColorsToPlay = Object.keys(cardsToPlayByColors);
    if (allColorsToPlay.length === 1 && cardsToPlayByColors[allColorsToPlay[0]].length === 1) {
      allSelectedCards.push(cardsToPlayByColors[allColorsToPlay[0]][0]);
      allColorsToPlay = [];
    }

    setSelectedCards(allSelectedCards);

    const hasCards = isRandom ? allColorsToPlay.length : cardsToPlay.length;

    if (hasCards) {
      if (isRandom) {
        getNextCardToPlay(cardsToPlayByColors);
      } else {
        getNextCardsToPlayDirect(cardsToPlay);
      }
    } else {
      setCardsInPlay([]);
      onFinished(allSelectedCards);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.field}>
        {cardsInPlay.length > 0 && (
          <div className={styles.playTable}>
            {cardsInPlay.map((item: ICard) => (
              <Card key={item.name} item={item} isClickable onClick={() => onPlayCardClick(item)} />
            ))}
          </div>
        )}
        {cardsInPlay.length === 0 && children}
      </div>
      {selectedCards.length > 0 && (
        <div className={styles.selectedCards}>
          {selectedCards.map((item: ICard, index) => (
            <Card key={index} item={item} isClickable={false} size="small" />
          ))}
        </div>
      )}
    </div>
  );
};

export default PlayTable;
