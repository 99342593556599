import React, { useEffect, useState } from 'react';
import { ICard } from '../../interfaces/card.interface';
import styles from './index.module.css';
import classnames from 'classnames';
import { whiteColors } from '../../services/constants';
import { Badge, Modal } from 'antd';
import descriptions from '../../descriptions.json';
import type { IDescriptionColor } from '../../interfaces/description-color.interface';

interface IFinalResultParams {
  cards: ICard[];
}

const FinalResult = ({ cards }: IFinalResultParams) => {
  const [cardsByColors, setCardsByColors] = useState([] as Array<ICard[]>);
  const [showInfoColor, setShowInfoColor] = useState(null as unknown as IDescriptionColor);

  useEffect(() => {
    const cardsByColor: { [key: string]: ICard[] } = {};

    cards.forEach((item: ICard) => {
      cardsByColor[item.color] = cardsByColor[item.color] || [];
      cardsByColor[item.color].push(item);
    });

    const groupedCards = Object.values(cardsByColor);
    groupedCards.sort((a, b) => {
      if (a.length > b.length) {
        return -1;
      }
      if (a.length < b.length) {
        return 1;
      }
      return 0;
    });

    setCardsByColors(groupedCards);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.cards}>
      {cardsByColors.map((cards: ICard[]) => (
        <div
          key={cards[0].color}
          className={classnames(styles.item, whiteColors.includes(cards[0].color) ? styles['--white'] : null)}
          style={{ backgroundColor: cards[0].color }}
          onClick={() => {
            const data = (descriptions as IDescriptionColor[]).find((item) => item.color === cards[0].color);
            setShowInfoColor(data as IDescriptionColor);
          }}
        >
          <Badge count={cards.length} className={styles.badge} />
          <ul>
            {cards.map((card) => (
              <li key={card.name}>{card.name}</li>
            ))}
          </ul>
        </div>
      ))}
      <Modal
        title=""
        visible={!!showInfoColor}
        footer={null}
        centered
        closable={false}
        width="80%"
        bodyStyle={{ padding: 0 }}
        onCancel={() => setShowInfoColor(null as unknown as IDescriptionColor)}
      >
        <div className={styles.header} style={{ backgroundColor: showInfoColor?.color }}>
          <div className={styles.headerBody}>Тип професії: {showInfoColor?.type.toUpperCase()}</div>
        </div>
        <div className={styles.content} style={{ backgroundColor: showInfoColor?.color }}>
          <div className={styles.contentBlock}>
            <div className={styles.contentTitle}>Здібності:</div>
            <ul className={styles.contentItems}>
              {showInfoColor?.capabilities.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
          <div className={classnames(styles.img, styles[showInfoColor?.img])} />
          <div className={styles.contentBlock}>
            <div className={styles.contentTitle}>Любить:</div>
            <ul className={styles.contentItems}>
              {showInfoColor?.like.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FinalResult;
