import React, { useState } from 'react';
import styles from './index.module.css';
import items from '../data.json';
import { Button } from 'antd';
import PlayTable from '../components/play-table';
import { ICard } from '../interfaces/card.interface';
import FinalResult from '../components/final-result';

const App = () => {
  const [firstIterationCards, setFirstIterationCards] = useState([] as ICard[]);
  const [secondIterationCards, setSecondIterationCards] = useState([] as ICard[]);
  const [startSecondIteration, setStartSecondIteration] = useState(false);

  const isStatic = window.location.pathname.includes('static');

  return (
    <div className={styles.app}>
      {!startSecondIteration && (
        <PlayTable cards={items} onFinished={setFirstIterationCards} isRandom={!isStatic}>
          <div className={styles.resultBlock}>
            <div className={styles.resultContainer}>
              <div className={styles.resultHeader}>
                <div className={styles.rabbit} />
                <div className={styles.resultTitle}>Продовжуємо гру - 2 етап</div>
              </div>
              <div className={styles.resultImg} />
              <Button
                className={styles.nextBtn}
                size="large"
                type="primary"
                onClick={() => setStartSecondIteration(true)}
              >
                Продовжити
              </Button>
            </div>
          </div>
        </PlayTable>
      )}
      {startSecondIteration && (
        <PlayTable cards={firstIterationCards} onFinished={setSecondIterationCards} isRandom={true}>
          <div className={styles.resultBlock}>
            <FinalResult cards={secondIterationCards} />
          </div>
        </PlayTable>
      )}
    </div>
  );
};

export default App;
